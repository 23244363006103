<template>
  <layout-private  :customer="customer">

      <div class="row">
        <div class="col-6 col-lg-8">
          <h1 class="title-short">Stats</h1>
        </div> <!-- /.col-x -->
        <div class="col-6 col-lg-4">
          <select v-if="locations && Object.keys(locations).length" v-model="activeLocationId" class="form-control">
            <option disabled :value="null">Select a Location</option>
            <option v-for="location in locations" :value="location.id">{{ location.name }}</option>
          </select>
        </div> <!-- /.col-x -->
        
        <!-- team stats: caught-up chart -->
        <stats-caught-up></stats-caught-up>

        <!-- patient stats: care progress rate -->
        <stats-care-progress v-if="locations && Object.keys(locations).length" :activeLocationId="activeLocationId" :activeLocation="activeLocation"></stats-care-progress>

        <!-- practice stats: practice health goal -->
        <stats-practice-health v-if="locations && Object.keys(locations).length" :activeLocationId="activeLocationId" :activeLocation="activeLocation"></stats-practice-health>

      </div> <!-- /.row -->
  </layout-private>
</template>

<script>
import Vue from 'vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import StatsCaughtUp from '../components/StatsCaughtUp.vue'
import StatsCareProgress from '../components/StatsCareProgress.vue'
import StatsPracticeHealth from '../components/StatsPracticeHealth.vue'

export default {
  props: ['customer'],
  components: {
    LayoutPrivate, ListErrors, StatsCaughtUp, StatsCareProgress, StatsPracticeHealth,
  },
  data () {
    return {
      errors: [],
      patientStatLocation: null,
      practiceStatLocation: null,
      locations: {},
      activeLocationId: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    activeLocation(){
      if(this.activeLocationId && this.locations && Object.keys(this.locations).length) {
        return this.locations[this.activeLocationId];
      }
      return null;
    },
  },
  watch: {
    // check for redirect on loading custom claims
    custom_claims() {
      if(!this.custom_claims || !this.custom_claims.adminLevel || this.custom_claims.adminLevel !== "superAdmin") {
        this.$router.replace('/')
      }
      this.getLocations();
    },
  },
  methods: {
    getLocations() {
      // exit if no sufficient claims
      if(!this.custom_claims || !this.custom_claims.adminLevel || !this.custom_claims.customerId) {
        return;
      }
      // get all locations
      Vue.firestore.collectionGroup('locations')
          .where('customerId', '==', this.custom_claims.customerId)
          .get()
          .then(locsQuerySnap => {
            locsQuerySnap.docs.forEach((loc,i) => {
              // console.log(`loc ${i}`,loc.data());
              // do not add hidden locations
              if(!loc.data().hidden) {
                this.$set(this.locations, loc.id, {
                  customerId: this.custom_claims.customerId,
                  name: loc.data().locationName,
                  id: loc.id,
                  groupId: loc.data().groupId,
                  hidden: loc.data().hidden || null,
                  openDentalStatus: loc.data().open_dental_status || null,
                  monthlyRevenueGoal: loc.data().monthly_revenue_goal || null,
                  monthlyDays: loc.data().monthly_open_days || null,
                });
             }
            })
          })
          .catch(err => console.log(err));
    },
  },
  mounted () {
    this.getLocations();
  },
}
</script>

<style lang="less" scoped>

h3 {
  margin: 50px 0 10px;
}
h3:first-child {
  margin-top: 0;
}
</style>
