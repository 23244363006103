<template lang="html">
  <div class="col-12 col-lg-6 col-xl-8">
    <hr class="hr-chart" />
    <p class="subtitle"><strong>Patient Health</strong></p>
    <h3>Care Progress Rate</h3>
    <p>Percentage of patients with unmet care who have an appointment scheduled.</p>

    <p v-if="!activeLocationId || !activeLocation" class="light-weight"><em>Please select a location above.</em></p>

    <div v-if="activeLocation && (!activeLocation.openDentalStatus || activeLocation.openDentalStatus !== 'active')">
      <p class="small"><em>No Open Dental connection found for this location.</em></p>
    </div>

    <span v-if="activeLocation && activeLocation.openDentalStatus && !dailyValues.length"><i class="far fa-fw fa-pulse fa-spinner"></i></span>

    <div v-if="activeLocation && activeLocation.openDentalStatus && activeLocation.openDentalStatus == 'active'" class="chart chart-bars chart-care-progress">
      <div class="bars-container d-flex flex-row">
        <div class="labels-y">
          <p><span>100%</span>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><span>50%</span>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
        <!-- use flex reverse to show items in correct order since they were loaded in desc but need to show in asc -->
        <div class="flex-fill d-flex flex-row flex-row-reverse justify-content-end">
          <div
            v-for="(dVal,i) in dailyValues"
            class="bar-container d-flex flex-column justify-content-end"
            
            @click="toggleActiveBar(i)"
            :class="[{active: activeBar==i},decideBarColorClass(dVal)]"
          >
            <p v-if="dVal!=='error'" class="label" :style="{bottom: dVal + '%'}">{{ dVal }}%</p>
            <div v-if="dVal!=='error'" class="bar" :style="{height: dVal + '%'}"></div>
            <div v-if="dVal=='error'" class="bar bar-error"><small>no data</small></div>
          </div>
        </div>
        
      </div> <!-- /.bars-container -->
      
      <div class="labels-x d-flex flex-row justify-content-between">
        <p>2 wk ago</p>
        <p>1 wk ago</p>
        <p>yesterday</p>
      </div> <!-- /.labels-x -->
    </div> <!-- /.chart -->

    

  </div> <!-- /.col -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'

export default {
  props: ['activeLocationId','activeLocation'],
  data () {
    return {
      dailyValues: [],
      activeBar: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
  },
  watch: {
    activeLocationId() {
      if(this.activeLocationId && this.activeLocation) {
        this.loadPatientStats();
      }
    },
  },
  methods: {
    toggleActiveBar(barIndex) {
      if(this.activeBar == barIndex) {
        this.activeBar = null;
      } else {
        this.activeBar = barIndex;
      }
    },
    decideBarColorClass(percentage) {
      if(percentage == "error") {
        return '';
      }
      if(percentage <= 30) {
        return 'red';
      }
      if(percentage <= 40) {
        return 'orange';
      }
      if(percentage <= 50) {
        return 'yellow';
      }
      if(percentage <= 60) {
        return 'blue';
      }
      if(percentage > 60) {
        return 'green';
      }
    },
    loadPatientStats() {
      if(!this.custom_claims || !this.custom_claims.customerId) {
        return;
      }
      this.dailyValues = [];

      Vue.firestore.collection(`customers/${this.custom_claims.customerId}/stats/care_progress_rate/${this.activeLocationId}`)
        .orderBy('timestamp','desc')
        .limit(14)
        .get()
        .then(statSnaps => {
          // console.log('statSnaps.docs',statSnaps.docs)
          if(statSnaps.empty) {
            console.log("error: empty query")
            return;
          }
          statSnaps.docs.forEach(stat => {
            // let dailyStat = stat.data();
            // console.log({dailyStat})
            let percentage = stat.data().percentage;
            if(percentage !== 'error') {
              percentage = Math.round(percentage);
            }
            this.dailyValues.push(percentage);
          })
        })
        .catch(err => console.log(err));
    },
  },
  mounted () {
    // this.loadPatientStats();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.hr-chart {
  border-top: 3px solid @dark-color;
  margin: 50px 0 30px;
}

.subtitle {
  margin: 0 0 5px;
}
h3 {
  margin: 0 0 10px;
}
.chart-care-progress {
  padding-top: 15px;

  .bars-container,
  .bar-container,
  .labels-y {
    height: 190px;
  }
  .bars-container {
    border-top: none; // override
  }
  .labels-x {
    padding-left: 45px; // account for labels-y

    p:first-child {
      border-left: 1px solid @dark-color;
      padding-left: 2px;
      margin-left: 3.57%;
    }
    p:last-child {
      border-right: 1px solid @dark-color;
      padding-right: 2px;
      margin-right: 3.57%;
    }
  }
  .bar-container {
    width: 14.28%;
    text-align: center;
  }
  .bar {
    width: 20px;
    margin: 0 auto; // center horizontally
  }
  .red .bar {
    background-color: @color-salmon;
  }
  .orange .bar {
    background-color: @color-orange;
  }
  .yellow .bar {
    background-color: @color-yellow;
  }
  .blue .bar {
    background-color: @color-blue;
  }
  .green .bar {
    background-color: @color-green;
  }
  .bar-error {
    height: 100%;
    border-color: @dim-color;
    border-style: dashed;
    position: relative;
    color: @dim-color;

    > small {
      transform: rotate(270deg);
      display: block;
      line-height: 1em;
      width: 50px;
      position: absolute;
      bottom: 1.75em;
      left: -1.25em;
    }
    // &:hover {
    //   background-color: @dim-color;
    //   color: @light-color;
    // }
  }
  .bar-container.active {
    cursor: pointer;

    .label {
      visibility: visible;
      opacity: 1;
    }
    .bar {
      background-color: @dark-color;
    }
  }
}

</style>
